import React from 'react'

export const App = () => {
  const FEATURE_ITEM = [
    {
      icon: 'thumbsUp',
      title: '今すぐ予約できる物件が多い',
      text: 'IoTで管理されている物件が多いため、今すぐの予約・利用が可能に',
    },
    {
      icon: 'sofa',
      title: '高品質なスペースが多い',
      text: 'スペースの掲載審査を厳格化しているため、ハズレが少ない',
    },
    {
      icon: 'usePhone',
      title: '使いやすいアプリケーション',
      text: '地図上からスペースを見つけやすい仕様になっているため使いやすい',
    },
    {
      icon: 'home',
      title: '直営店の運営',
      text: 'アドカリ直営の物件も運営しているため、安心して利用できる',
    },
  ]

  type Props = {
    icon: string
    title: string
    text: string
  }

  const FeatureItem = ({ ...item }: Props) => (
    <div className={'grid grid-rows-[80px_auto] gap-3'} key={item.icon}>
      {/*eslint-disable quotes */}
      <div className={`max-h-full h-full mx-auto`}>
        <img src={`./img/${item.icon}.png`} alt="" className={'h-full'} />
      </div>
      {/*eslint-enable quotes*/}
      <div>
        <h2 className={'text-main font-bold mb-2'}>{item.title}</h2>
        <p>{item.text}</p>
      </div>
    </div>
  )

  return (
    <main className={'grid md:gap-[100px] gap-8 text-black pb-5'}>
      <section className={'bg-main py-5 md:py-20'}>
        <div className={'container mx-auto grid md:grid-cols-2'}>
          <div>
            <p className={'text-white font-bold text-sm md:text-xl text-center mb-4'}>レンタルスペース検索サイト</p>
            <div
              className={'bg-white py-8 mb-9'}
              style={{ marginLeft: 'calc(50% - 50vw)', paddingLeft: 'calc(50vw - 50%)' }}
            >
              <div className={'max-w-[230px] md:max-w-540 mx-auto w-full'}>
                <img src={'./img/logo.png'} alt={''} />
              </div>
            </div>
            <p className={'text-white font-bold text-2xl md:text-3xl text-center mb-8'}>年内サービス開始予定！</p>
          </div>
          <div className={'max-w-[386px] w-full mx-auto'}>
            <img src={'./img/device-mockup.png'} alt={''} />
          </div>
        </div>
      </section>
      <section className={'container mx-auto md:max-w-[850px] px-5'}>
        <h1 className={'font-bold text-2xl md:text-4xl md:text-center mb-12'}>addcari(アドカリ)とは？</h1>
        <div className={'grid gap-5 md:text-center'}>
          <p>アドカリはレンタルスペースの検索サイトです。</p>
          <p>
            ちょっと休憩したい、飲み会のあとの2次会どこでしよう？といった時、”今すぐ”近くのスペースが利用できるサービスです。
          </p>
          <p>
            アドカリにスペース登録するオーナーさまが、自社で開発したIoT(stak)を導入することで、スペースの運営管理がスマホ1つでできるようになるため、”今すぐ”のスペース利用が可能になりました。
          </p>
          <p>
            また、stakを導入したスペースをご利用になる場合は、対面による鍵の受け渡しが不要（スマートロック）だったり、エアコンの操作も事前にできるようになるため、スペース到着時には快適な空間で過ごすことができます。
          </p>
        </div>
      </section>
      <section className={'container mx-auto md:max-w-[550px] px-5'}>
        <h1 className={'font-bold text-2xl md:text-4xl md:text-center mb-12'}>addcari(アドカリ)の特徴</h1>
        <div className={'grid gap-8 sm:grid-cols-2 sm::grid-rows-2 text-center'}>
          {FEATURE_ITEM.map((item) => (
            <FeatureItem key={item.icon} {...item} />
          ))}
        </div>
      </section>
      <small className={'text-center'}>運営会社 stak, Inc.</small>
    </main>
  )
}
